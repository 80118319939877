import React, { useState } from 'react';
import { Form, Input, Button, Modal } from 'antd';
import axios from 'axios';
import { toast } from 'react-toastify';
import "../styles/map.css";

function PassChange() {
    const [form] = Form.useForm();

    const onFinish = (values) => {
        // axios.post('http://192.168.202.41:8081/api/observatory/auth/changepassword/superadmin', {
        axios.post('https://security.observator.uz/api/observatory/auth/changepassword/superadmin', {
            oldPassword: values.oldPassword,
            newPassword: values.newPassword,
            confirmationPassword: values.confirmPassword
        }, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        }).then(res => {
            toast.success("Parol muvaffaqiyatli o'zgardi!");
        }).catch(err => {
            toast.error("Parol o'zgarmadi. Qayta urining!");
        });
    };

    return (
        <div className="back">
            <Form form={form} onFinish={onFinish} className="w-50 card p-4">
                <Form.Item
                    name="oldPassword"
                    label="Eski parol"
                    rules={[{ required: true, message: 'Eski parolni kiriting' }]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    name="newPassword"
                    label="Yangi parol"
                    rules={[{ required: true, message: 'Yangi parolni kiriting' }]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    name="confirmPassword"
                    label="Yani parolni tasdiqlash"
                    dependencies={['newPassword']}
                    rules={[
                        { required: true, message: 'Yangi parolni tasdiqlang' },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('newPassword') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('Parollar bir xil emas!'));
                            }
                        })
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Parolni o'zgartirish
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}

export default PassChange;
